import React, { Fragment, useState, useEffect } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import TranscriberService from "../../../services/TranscriberService";
import { getErrorMessage } from "../../../helpers/functions";
import theme from "../../../assets/theme";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = ["Cases", _.startCase(resolutionKind), "Transcript"];
  const [transcriptData, setTranscriptData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const caseId = id?.id;
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      modified: false,
      count: 0,
      mediaId: "",
      transcriptionFlag: "",
    },
  ]);
  const [meetingId, setMeetingId] = useState();
  const [discard, setDiscard] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      modified: false,
      mediaId: "",
      transcriptionFlag: "",
    },
  ]);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [render, setRender] = useState(false);
  const [filterSpeaker, setFilterSpeaker] = useState();
  let LiveTranscription = true;

  useEffect(() => {
    async function getData(caseId) {
      try {
        setLoader({ state: true, message: "Fetching Transcription..." });
        const transcriptionControl =
          await TranscriberService.GetMeetingAccesscases(caseId);
        const res = await TranscriberService.getLiveTranscriptionData(caseId);
        if (res) {
          const lastObject =
            res?.transcriptionData?.words[
            res?.transcriptionData?.words?.length - 1
            ];

          const newObject = { ...lastObject, text: "End Of Transcript" };
          if (
            res?.transcriptionData?.words?.length > 0 &&
            transcriptionControl?.transcriptionRunningStatus !== true
          ) {
            setData([
              ...res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
              newObject,
            ]);
            setDiscard([
              ...res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
              newObject,
            ]);
          } else {
            setData(
              res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
            );
            setDiscard(
              res?.transcriptionData?.words?.map((el) => {
                return {
                  id: el?.id,
                  end: el?.end,
                  confidence: el?.confidence,
                  text: el?.text,
                  start: el?.start,
                  speaker: el?.speaker,
                  mediaId: el?.mediaId,
                  transcriptionFlag: el?.transcriptionFlag,
                };
              }),
            );
          }
          setMeetingId(res?.meetingId);
          setTranscriptData(res);
          setRender(true);
        }
      } catch (error) {
        console.log("failed");
      } finally {
        setLoader({ state: false });
      }
    }
    if (refreshDetails) {
      getData(caseId);
      setRefreshDetails(false);
    }
  }, [refreshDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/transcriber/cases?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/transcriber/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  const updateLiveTranscriptionData = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      let storedData = data;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
        speakerNameObj: filterSpeaker,
      };
      if (storedData?.length) {
        const res = await TranscriberService.updateLiveTranscriptionData(
          payload,
          meetingId,
        );
        if (res?.updated) {
          setRefreshDetails(true);
        }
        if (res?.message) {
          enqueueSnackbar(res?.message, {
            variant: "success",
          });
        }
      } else {
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const discardChanges = () => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
          transcriptionFlag: el?.transcriptionFlag,
        };
      }),
    );
    setRender(true);
  };

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => setRefreshDetails(true),
      },
      hidden: data ? true : false,
    },
    {
      text: "Discard",
      props: {
        onClick: () => discardChanges(),
      },
      hidden: data ? false : true,
    },
    {
      text: "Save (alt + s)",
      props: {
        onClick: () => updateLiveTranscriptionData(data),
      },
      disabled: transcriptData?.transcriberEditMode ? false : true,
      hidden: data ? false : true,
    },
  ];

  const autoSaveupdateTranscriptionData = async (data) => {
    try {
      let storedData = data;
      storedData.pop();
      const payload = {
        editedTranscriptionData: storedData,
        speakerNameObj: filterSpeaker,
      };
      if (storedData?.length) {
        const res = await TranscriberService.updateLiveTranscriptionData(
          payload,
          meetingId,
        );
        if (res?.message) {
        }
      } else {
        setRefreshDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  return (
    <Fragment>
      <ActionBar
        {...{ breadcrumbs, onBreadcrumbClick, actions, transcriptData }}
      />
      <Container>
        {transcriptData?.transcriptionData ? (
          <TranscriptEditor
            {...{
              caseId,
              meetingId,
              data,
              render,
              setRender,
              setFilterSpeaker,
              LiveTranscription,
            }}
            mediaUrl={transcriptData?.audioFileUrl}
            setData={setData}
            title={history?.state?.title}
            updateState={updateLiveTranscriptionData}
            transcriberAccess={transcriptData?.transcriberEditMode}
            autoSaveupdateTranscriptionData={autoSaveupdateTranscriptionData}
          />
        ) : (
          <StyledTableEmptyBlock>
            <h2>
              {
                "Welcome to IIAC live transcription services. Your session has either not started or your live transcript is currently being generated."
              }
            </h2>
          </StyledTableEmptyBlock>
        )}
      </Container>
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
